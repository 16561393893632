import * as React from 'react';
import styled from 'styled-components';
import Svg from '@svg/index';
import { InputField } from '@ui-library/input';
import { FixMe } from '@utils/types';
import { Flex } from '@ui-library/flex';
import theme from '@css/theme';
import { Select, Option } from '@ui-library/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LargeText } from '@ui-library/typographyV2';

const Row = styled(Flex)`
  width: 100%;
  justify-content: 'flex-start';
`;

const Column = styled.div`
  flex: 1;
  input {
    width: 100%;
  }
`;
const ButtonText = styled(LargeText)`
  margin-left: 10px;
  color: ${theme.token.colorPrimary};
`;

interface ISingleSelectOption {
  name: string;
  value: string;
}

interface IDynamicSelectFormProps {
  field: FixMe;
  // eslint-disable-next-line react/no-unused-prop-types
  disabled: boolean;
  options: ISingleSelectOption[];
  onChange: FixMe;
}

interface IDynamicSelectFormState {
  mapping: FixMe;
  count: FixMe;
}

/* eslint-disable import/no-default-export */
export default class DynamicSelectForm extends React.Component<
  IDynamicSelectFormProps,
  IDynamicSelectFormState
> {
  constructor(props: IDynamicSelectFormProps) {
    super(props);
    this.state = {
      mapping: [],
      count: 0,
    };
  }

  componentDidMount() {
    const { field, onChange } = this.props;
    if (field.default) {
      this.setState({ mapping: [...field.default], count: field.default.length }, () =>
        onChange(field.value, this.state.mapping),
      );
    } else {
      const { keyLeft, keyRight, value } = field;
      this.setState(
        {
          mapping: [
            {
              [keyLeft]: '',
              [keyRight]: '',
            },
          ],
          count: 1,
        },
        () => onChange(value, this.state.mapping),
      );
    }
  }

  public onInputChange = (index: number, value: string, isLeft: boolean) => {
    const { onChange, field } = this.props;
    const { keyLeft } = field;
    const { keyRight } = field;
    this.setState(
      (prevState: FixMe) => {
        const mapping = prevState.mapping.splice(0);
        const updatedItem = {
          ...mapping[index],
          [isLeft ? keyLeft : keyRight]: value,
        };
        mapping.splice(index, 1, updatedItem);
        return {
          ...prevState,
          mapping,
        };
      },
      () => onChange(field.value, this.state.mapping),
    );
  };

  public handleDeleteRow = (index: number) => {
    const { onChange, field } = this.props;

    this.setState(
      (prevState: FixMe) => {
        const mapping = prevState.mapping.splice(0);
        mapping.splice(index, 1);
        const count = prevState.count - 1;
        return {
          ...prevState,
          mapping,
          count,
        };
      },
      () => onChange(field.value, this.state.mapping),
    );
  };

  public renderDropdown = (options: ISingleSelectOption[]) =>
    options &&
    options.map((opt: ISingleSelectOption) => (
      <Option value={opt.value} key={`dynamic-select-opt-key-${opt.value}`}>
        {opt.name}
      </Option>
    ));

  public renderSingleRow = (index: number) => {
    const { field, options } = this.props;
    const { mapping } = this.state;
    const { keyLeft, keyRight, placeholderLeft, placeholderRight, reverse } = field;
    return (
      <Row className="m-t-xs m-b-xs" key={index}>
        {reverse ? (
          <>
            <Column className="p-r-sm">
              <Select
                defaultValue={mapping[index] && mapping[index][keyLeft]}
                style={{ width: 120 }}
                onChange={(e: FixMe) => this.onInputChange(index, e, true)}
              >
                {this.renderDropdown(options)}
              </Select>
            </Column>
            <Column className="p-l-sm">
              <InputField
                value={mapping[index] && mapping[index][keyRight]}
                placeholder={placeholderRight}
                onChange={(e) => this.onInputChange(index, e.target.value, false)}
                data-testid="input"
              />
            </Column>
          </>
        ) : (
          <>
            <Column className="p-r-sm">
              <InputField
                value={mapping[index] && mapping[index][keyLeft]}
                placeholder={placeholderLeft}
                onChange={(e: FixMe) => this.onInputChange(index, e.target.value, true)}
                data-testid="input"
              />
            </Column>
            <Column className="p-l-sm">
              <Select
                defaultValue={mapping[index] && mapping[index][keyRight]}
                style={{ width: 120 }}
                onChange={(e: FixMe) => this.onInputChange(index, e, false)}
              >
                {this.renderDropdown(options)}
              </Select>
            </Column>
          </>
        )}
        {mapping.length > 1 && (
          <Column
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            className="p-l-sm"
          >
            <Svg name="delete" onClick={() => this.handleDeleteRow(index)} />
          </Column>
        )}
      </Row>
    );
  };

  updateCount = () => {
    this.setState((prevState) => ({ count: prevState.count + 1 }));
  };

  public render() {
    const { field } = this.props;
    const { count, mapping } = this.state;

    return (
      <div data-testid="dynamicSelectForm">
        <Row>
          <LargeText className="m-b-xs">{field.label}</LargeText>
        </Row>
        <Row>
          <Column className="p-r-sm">{field.labelLeft}</Column>
          <Column className="p-l-sm">{field.labelRight}</Column>
          {mapping.length > 1 && <Column className="p-l-sm" />}
        </Row>
        {[...Array(count)].map((_v: FixMe, index: number) => this.renderSingleRow(index))}
        <a onClick={this.updateCount} data-testid="anchor">
          <div className="p-t-sm">
            <FontAwesomeIcon icon={regular('plus')} color={theme.token.colorPrimary} />
            <ButtonText> ADD MORE</ButtonText>
          </div>
        </a>
      </div>
    );
  }
}
