import { getApiErrorMessage } from '@components/common/util/util';
import { apiAuthCaller } from '@services/apiCaller';
import { AxiosResponse } from 'axios';
import { action, observable, makeObservable } from 'mobx';
import { IFeaturesStore } from './features';
import { Member, OrganizationRole, WorkspaceRole } from './member';
import { IMessageStore } from './messages';
import { IUserStore } from './user';
import { IWorkspaceStore } from './workspace';

type Permission = { locked: boolean; permissionId: string | undefined };
export type Resource = 'source' | 'destination' | 'transformation' | 'model' | 'workspace';

export type Query =
  | {
      action: 'edit' | 'pii';
      resourceType: 'workspace';
    }
  | {
      resourceType: 'source' | 'destination' | 'model' | 'transformation';
      action: 'create';
    }
  | {
      resourceType: 'source' | 'destination';
      action: 'edit' | 'delete';
      resourceId: string;
    }
  | {
      resourceType: 'model';
      action: 'edit';
      resourceId: string;
    }
  | {
      resourceType: 'transformation';
      action: 'edit';
    };

export interface IPermissions {
  resources: undefined | Record<string, Permission>;
  registerResource(
    items: { id: string; permissionId: string | undefined; locked: boolean | undefined }[],
  ): void;
  createPermission(resourceId: string, users: string[], resourceType: Resource): Promise<boolean>;
  updateResourceAccess(
    resourceId: string,
    permissionId: string,
    isLocked: boolean,
    users: string[],
    resourceType: Resource,
  ): Promise<boolean>;
  addNewResource(id: string): void;
  hasPermission: (query: Query) => boolean;
  authorizedForAllowedList: (
    resourceType: 'source' | 'destination' | 'model',
    user: Member,
  ) => boolean;

  // PII permissions
  setDataPermissions(payload: { id: string; isLocked: boolean }): void;
  dataPermissions: undefined | Permission;
  getAllowedWorkspaceRoles: () => WorkspaceRole[];
}

type RootStore = {
  userStore: IUserStore;
  messagesStore: IMessageStore;
  workspaceStore: IWorkspaceStore;
  featuresStore: IFeaturesStore;
};

export class PermissionsStore implements IPermissions {
  rootStore: RootStore;

  @observable resources: undefined | Record<string, Permission>;

  @observable dataPermissions: undefined | Permission;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  hasPermission(query: Query) {
    const { userStore } = this.rootStore;
    const user = { roles: userStore.roles };
    switch (query.resourceType) {
      case 'workspace':
        if (query.action === 'pii') {
          return !!this.dataPermissions && !this.dataPermissions.locked;
        }
        if (query.action === 'edit') {
          return this.hasRole(user, WorkspaceRole.connectionsAdmin);
        }
        return false;
      case 'source':
      case 'destination':
        if (query.action !== 'create' && this.isLocked(query.resourceId)) {
          return false;
        }
        if (this.hasRole(user, WorkspaceRole.connectionsAdmin)) {
          return true;
        }
        if (query.action === 'edit') {
          return this.hasRole(user, WorkspaceRole.connectionsEdit);
        }
        return false;
      case 'model':
        if (query.action !== 'create' && this.isLocked(query.resourceId)) {
          return false;
        }
        return this.hasRole(user, WorkspaceRole.modelsAdmin, WorkspaceRole.connectionsAdmin);
      case 'transformation':
        return this.hasRole(user, WorkspaceRole.transformationsAdmin);
      default:
        return false;
    }
  }

  private isLocked(resourceId: string) {
    if (this.resources) {
      const resource = this.resources[resourceId];
      return !resource || resource.locked;
    }
    return true;
  }

  authorizedForAllowedList(
    resourceType: 'source' | 'destination' | 'model',
    user: Pick<Member, 'roles'>,
  ) {
    // TODO - Add tests
    switch (resourceType) {
      case 'source':
      case 'destination':
        if (this.hasRole(user, WorkspaceRole.connectionsAdmin)) {
          return true;
        }
        return this.hasRole(user, WorkspaceRole.connectionsEdit);
      case 'model':
        return this.hasRole(user, WorkspaceRole.modelsAdmin, WorkspaceRole.connectionsAdmin);
      default:
        return false;
    }
  }

  getAllowedWorkspaceRoles() {
    const plan = this.rootStore.featuresStore.getFeatureConfiguration('WORKSPACE_ROLES');
    switch (plan) {
      case 'BASIC':
        return [
          WorkspaceRole.workspaceRead,
          WorkspaceRole.connectionsAdmin,
          WorkspaceRole.connectionsEdit,
          WorkspaceRole.transformationsAdmin,
        ];
      case 'EXPANDED':
        return [
          WorkspaceRole.workspaceRead,
          WorkspaceRole.connectionsAdmin,
          WorkspaceRole.connectionsEdit,
          WorkspaceRole.transformationsAdmin,
          WorkspaceRole.modelsAdmin,
        ];
      default:
        return [WorkspaceRole.workspaceRead];
    }
  }

  private hasRole(user: Pick<Member, 'roles'>, role: WorkspaceRole, fallbackRole?: WorkspaceRole) {
    const { workspaceStore } = this.rootStore;
    if (user.roles?.organization === OrganizationRole.admin) {
      return true;
    }
    const allowedRoles = this.getAllowedWorkspaceRoles();
    const workspaceRole = user.roles?.workspaces?.[workspaceStore.id];
    if (!allowedRoles.includes(role)) {
      if (fallbackRole && allowedRoles.includes(fallbackRole)) {
        return workspaceRole?.includes(fallbackRole) || false;
      }
      return false;
    }
    return workspaceRole?.includes(role) || false;
  }

  registerResource(
    items: { id: string; permissionId: string | undefined; locked: boolean | undefined }[],
  ) {
    this.resources = items.reduce(
      (acc, elem) => ({
        ...acc,
        [elem.id]: {
          locked: elem.locked || false,
          permissionId: elem.permissionId || '',
        },
      }),
      this.resources,
    );
  }

  createPermission(resourceId: string, users: string[], resourceType: Resource) {
    const {
      userStore: { id: userId },
      messagesStore,
    } = this.rootStore;
    return apiAuthCaller()
      .post('/permissions', { locked: true, users, resourceId, resourceType })
      .then((res: AxiosResponse<{ permissionId: string }>) => {
        const result = {
          permissionId: res.data.permissionId,
          locked: !users.includes(userId),
        };
        if (resourceType === 'workspace') {
          this.dataPermissions = result;
        } else {
          this.resources![resourceId] = result;
        }
        return true;
      })
      .catch((err) => {
        messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to lock the resource'));
        return false;
      });
  }

  updateResourceAccess(
    resourceId: string,
    permissionId: string,
    isLocked: boolean,
    users: string[],
    resourceType: Resource,
  ) {
    const {
      userStore: { id: userId },
      messagesStore,
    } = this.rootStore;
    const resource = this.resources?.[resourceId];
    return apiAuthCaller()
      .patch(`/permissions/${permissionId}`, { locked: isLocked, users })
      .then(() => {
        const locked = isLocked && !users.includes(userId);
        if (resourceType === 'workspace') {
          this.dataPermissions = { ...this.dataPermissions!, locked };
        } else {
          this.resources![resourceId] = { ...resource!, locked };
        }
        return true;
      })
      .catch((err) => {
        messagesStore.showErrorMessage(
          getApiErrorMessage(err, 'Failed to update resource permissions'),
        );
        return false;
      });
  }

  setDataPermissions(payload: { id: string; isLocked: boolean }) {
    const { isLocked = false, id = '' } = payload || {};
    this.dataPermissions = { locked: isLocked, permissionId: id };
  }

  addNewResource(resourceId: string) {
    this.registerResource([{ id: resourceId, permissionId: '', locked: false }]);
  }
}
