import { css } from 'styled-components';
import { popoverCommonStyle, popoverDefaultStyle, popoverPromotionalStyle } from './popover/styles';
import { tooltipDefaultStyle } from './tooltip/styles';
import { utilities } from './utilities';

/* The map to antd Modal sizes */
const modalWidthConstraints = css`
  .sm_modal {
    width: 400px;
  }

  .md_modal {
    width: 600px;
  }

  .lg_modal {
    width: 800px;
  }

  .xl_modal {
    width: 968px;
  }

  .xxl_modal {
    width: 1280px;
  }
`;

const notificationDefaultStyle = css`
  .notification {
    width: 400px;

    padding: ${({ theme }) => theme.token.paddingMD}px ${({ theme }) => theme.token.paddingLG}px;
    border-radius: ${({ theme }) => theme.token.borderRadius}px;
    background-color: ${({ theme }) => theme.token.colorBgBase};
    box-shadow: ${({ theme }) => theme.token.boxShadowSecondary};
  }

  .success-notification {
    border: 1px solid ${({ theme }) => theme.token.colorSuccessBorder};
  }

  .error-notification {
    border: 1px solid ${({ theme }) => theme.token.colorErrorBorder};
  }

  .info-notification {
    border: 1px solid ${({ theme }) => theme.token.colorInfoBorder};
  }
`;

const messageDefaultStyle = css`
  .loading-message .ant-message-notice-content,
  .info-message .ant-message-notice-content {
    box-shadow: ${({ theme }) => theme.token.boxShadowSecondary};
    border: 1px solid ${({ theme }) => theme.token.colorPrimaryBase};
    border-radius: ${({ theme }) => theme.token.borderRadius}px;
    background-color: ${({ theme }) => theme.token.colorPrimaryBg};

    svg {
      color: ${({ theme }) => theme.token.colorPrimaryBase};
      margin-right: ${({ theme }) => theme.token.marginXXS}px;
    }
  }

  .success-message .ant-message-notice-content {
    box-shadow: ${({ theme }) => theme.token.boxShadowSecondary};
    border: 1px solid ${({ theme }) => theme.token.colorSuccessBorder};
    border-radius: ${({ theme }) => theme.token.borderRadius}px;
    background-color: ${({ theme }) => theme.token.colorSuccessBg};

    svg {
      color: ${({ theme }) => theme.token.colorSuccessBorder};
      margin-right: ${({ theme }) => theme.token.marginXXS}px;
    }
  }

  .error-message .ant-message-notice-content {
    box-shadow: ${({ theme }) => theme.token.boxShadowSecondary};
    border: 1px solid ${({ theme }) => theme.token.colorErrorBorder};
    border-radius: ${({ theme }) => theme.token.borderRadius}px;
    background-color: ${({ theme }) => theme.token.colorErrorBg};

    svg {
      color: ${({ theme }) => theme.token.colorErrorBorder};
      margin-right: ${({ theme }) => theme.token.marginXXS}px;
    }
  }

  .warning-message .ant-message-notice-content {
    box-shadow: ${({ theme }) => theme.token.boxShadowSecondary};
    border: 1px solid ${({ theme }) => theme.token.colorWarningBase};
    border-radius: ${({ theme }) => theme.token.borderRadius}px;
    background-color: ${({ theme }) => theme.token.colorWarningBg};

    svg {
      color: ${({ theme }) => theme.token.colorWarningBase};
      margin-right: ${({ theme }) => theme.token.marginXXS}px;
    }
  }
`;

const misc = css`
  .ant-layout {
    background-color: ${({ theme }) => theme.token.colorBgContainer};
  }

  /* TODO: export antd tweaks from the ui-library */
  .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 0px;
  }

  .ant-picker-now {
    display: none !important;
  }

  /* TODO: replace these with the actual Alert */
  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .alert-link {
    font-weight: 100;
  }

  .alert-primary {
    color: #004085;
    background-color: #ebf6ff;
    border-color: #b8daff;
  }

  .alert-secondary {
    color: #9a9a9a;
    background-color: #f2f2f2;
    border-color: #d6d8db;
  }

  .alert-secondary .alert-link {
    color: #0285ff;
  }
`;

const datePickerStyle = `
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: unset;
  }
`;

export const styles = css`
  ${misc}

  ${utilities}

  /* Modal */
  ${modalWidthConstraints}

  /* Tooltip */
  ${tooltipDefaultStyle}

  /* Popover */
  ${popoverCommonStyle}
  ${popoverDefaultStyle}
  ${popoverPromotionalStyle}

  /* Notification */
  ${notificationDefaultStyle}

  /* Message */
  ${messageDefaultStyle}
  ${datePickerStyle}
`;
