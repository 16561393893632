import { IColumn } from '@components/sources/source/warehouseSource/configureData/sourceSpecificConfig/types';
import { Mapping } from '@stores/source/types';
import { WarehouseSourceOrigin } from '@stores/types';
import removeNullValues from '@utils/removeNullValues';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { ISourceSettings } from './schemaHelpers';
import { ConnectionState, IMappingConfig, ISourceConfig } from './visualDataMappingProvider';

export const SYNC_BEHAVIOUR_MODES = {
  UPSERT: 'upsert',
  MIRROR: 'mirror',
};

export const isCompleteSourceConfig = (config: ISourceConfig, skipSchemaConfiguration?: boolean) =>
  Boolean(
    !skipSchemaConfiguration
      ? (config?.table?.schema && config?.table?.name) || config.sqlModelId || config.audienceId
      : config?.table?.bucketName,
  );

const skipSchemaConfig = (defName: string) => defName.toLowerCase() === 's3';

export const updateMappings = (
  mappings: { original: string; mapped: string }[],
  columns: string[],
  overridesConfig: { [i: string]: string },
) => {
  const newMappings = mappings?.reduce((accum: { original: string; mapped: string }[], col) => {
    if (
      col.mapped &&
      (columns.includes(col.original) || Object.keys(overridesConfig).includes(col.mapped))
    ) {
      accum.push(col);
    }

    return accum;
  }, []);
  return newMappings;
};
export const addUserIdInColumns = (
  mappings: { original: string; mapped: string }[],
  columns: string[],
  overridesConfig: { [i: string]: string },
) => {
  columns = columns || [];
  const columns_to_add = Object.values(overridesConfig || []).filter(
    (col) => !columns.includes(col),
  );
  columns.push(...columns_to_add);

  const filtered_original_cols = mappings?.reduce((acc: string[], { original }: Mapping) => {
    if (!acc.includes(original)) {
      acc.push(original);
    }
    return acc;
  }, []);

  columns = columns.filter((col) => filtered_original_cols.includes(col));
  return uniq(columns);
};

const buildJsonMapperConfig = (config: ISourceSettings) => {
  const {
    accountInfo,
    customResources,
    credentials,
    accessToken,
    refreshToken,
    rudderAccountId,
    ...updatedConfig
  } = config;
  updatedConfig.mappings = updateMappings(
    updatedConfig.mappings || [],
    updatedConfig.query?.columns || updatedConfig.columns || [],
    updatedConfig.overridesConfig || {},
  );
  const newCols = addUserIdInColumns(
    updatedConfig.mappings,
    updatedConfig.query?.columns || updatedConfig.columns || [],
    updatedConfig.overridesConfig || {},
  );
  if (updatedConfig.query?.columns) {
    updatedConfig.query.columns = newCols;
  } else if (updatedConfig.columns) {
    updatedConfig.columns = newCols;
  }
  delete updatedConfig.overridesConfig;

  return removeNullValues(updatedConfig);
};
const generatePreview = (rows: Array<Record<string, unknown>>, columns: IColumn[]) => {
  if (rows.length === 0) {
    return {};
  }
  const preview = rows[0];
  const tableData = columns.reduce((accum: { [column: string]: unknown }, col) => {
    const currentValue = preview[col.name];
    const newAccum = accum;
    newAccum[col.name] = currentValue;
    return accum;
  }, {});
  return tableData;
};

const convertToHourlyUTC = (t: string, method: 'ceil' | 'floor') => {
  // t is in UTC format
  if (method === 'floor') {
    return moment(t).utc().minutes(0).seconds(0).milliseconds(0).toISOString();
  }

  return moment(t).utc().add(1, 'hours').minutes(0).seconds(0).milliseconds(0).toISOString();
};

const getMappingByMappedValue = (
  mappedValue: string,
  mappings: { original: string; mapped: string }[],
) => mappings?.find((mapping) => mapping.mapped === mappedValue);

const isVisualDataMappingValid = (
  config: Partial<ConnectionState>,
  origin: WarehouseSourceOrigin,
  skipSchemaConfiguration: boolean,
  setConfig: (value: Partial<ConnectionState>) => void,
) => {
  const completeSourceConfig =
    config.sourceConfig && isCompleteSourceConfig(config.sourceConfig, skipSchemaConfiguration);
  if (!completeSourceConfig) {
    return 'Source configuration is incomplete';
  }
  const completeDestinationConfig = config.destinationConfig?.shouldCreateVendorAudience
    ? config.destinationConfig.vendorAudience?.name &&
      config.destinationConfig.vendorAudience?.description
    : !!config?.destinationConfig?.object?.name;

  if (!completeDestinationConfig) {
    return 'Destination configuration is incomplete';
  }
  let emptyColumns = 0;
  let invalidColumns = 0;
  let isEmptyUniqIdentifier = false;
  let invalidUniqIdentifier = false;
  let invalidAudienceIdentifier = false;
  const allMappings: IMappingConfig[] = [
    config?.uniqueIdentifierConfig || {},
    ...(config?.mappingConfig || [{}]),
  ];
  if (
    (origin === WarehouseSourceOrigin.AUDIENCE ||
      origin === WarehouseSourceOrigin.PROFILES_AUDIENCE) &&
    config.syncBehaviour?.mode === SYNC_BEHAVIOUR_MODES.UPSERT &&
    !config.audienceIdentifier
  ) {
    invalidAudienceIdentifier = true;
  } else {
    allMappings?.forEach((curr: IMappingConfig, index) => {
      if (index === 0 && (!curr.original || !curr.mapped)) {
        isEmptyUniqIdentifier = true;
      } else if (
        index === 0 &&
        config.columnMap &&
        curr.original &&
        !config.columnMap[curr.original]
      ) {
        invalidUniqIdentifier = true;
      } else if (index && !curr.original) {
        emptyColumns += 1;
      } else if (index && curr.original && config.columnMap && !config.columnMap[curr.original]) {
        invalidColumns += 1;
      }
    });
  }
  setConfig({ ...config, enteredSaveState: true });
  const invalidColumnsError = invalidColumns ? `${invalidColumns} invalid columns` : '';

  const emptyColumnsError = emptyColumns ? `${emptyColumns} unmapped fields` : '';
  let errorMessage = '';
  if (invalidAudienceIdentifier) {
    errorMessage = 'Audience identifier status is empty';
  } else if (isEmptyUniqIdentifier) {
    errorMessage = 'Unique identifier is empty';
  } else if (invalidUniqIdentifier) {
    errorMessage = 'Selected unique identifier not found in the warehouse';
  } else if (invalidColumns && emptyColumns) {
    errorMessage = `${invalidColumnsError} and ${emptyColumnsError}`;
  } else {
    errorMessage = invalidColumnsError || emptyColumnsError;
  }
  return errorMessage;
};

export {
  generatePreview,
  buildJsonMapperConfig,
  skipSchemaConfig,
  convertToHourlyUTC,
  getMappingByMappedValue,
  isVisualDataMappingValid,
};
