interface IConnections {
  [key: string]: string[];
}

export const isConnectionAvialable = (connections: IConnections) => {
  if (Object.keys(connections).length > 0) {
    return Object.keys(connections).some((item: string) => {
      const sources = connections[item];
      return sources !== null && sources.length > 0;
    });
  }
  return false;
};
