import * as React from 'react';
import { MonacoEditor } from '@components/common/codeEditor/monaco';
import { editorAutoHeight, editorDefaultFoldingLevel } from '@components/common/codeEditor/utils';
import classes from './styles.module.scss';
import { Flex } from '@ui-library/flex';

const MAX_HEIGHT = 400;

const parseMultipleLevels = (d: string): object | string => {
  let data = d.replace(/\r?\n|\r/g, ''); // replacing new line characters
  data = data.replace(/\s+/g, ' ').trim(); // replacing multiple spaces with single space
  try {
    const parsedData = JSON.parse(data);
    if (typeof parsedData === 'object') {
      const keys = Object.keys(parsedData);
      keys.forEach((key) => {
        if (typeof parsedData[key] === 'string') {
          parsedData[key] = parseMultipleLevels(parsedData[key]);
        }
      });
    }
    return parsedData;
  } catch (error) {
    return data;
  }
};

export const getFormattedSample = (data: string | object) => {
  const parsedData = typeof data === 'string' ? parseMultipleLevels(data) : data;
  if (typeof parsedData === 'object') {
    return {
      type: 'object',
      sample: JSON.stringify(parsedData, null, 2),
    };
  }
  return {
    type: 'string',
    sample: parsedData,
  };
};

interface Props {
  sample: string | object;
}

export const SampleError: React.FC<Props> = ({ sample: data }) => {
  const { type, sample } = getFormattedSample(data);

  return (
    <Flex className={classes.error_container}>
      {type === 'object' ? (
        <MonacoEditor
          onMount={(editor, monaco) => {
            editorAutoHeight(MAX_HEIGHT)(editor, monaco);
            editorDefaultFoldingLevel(2, MAX_HEIGHT)(editor, monaco);
          }}
          className={classes.error_editor}
          height={undefined}
          code={sample}
          readOnly
          language="json"
          hideLineNumber
        />
      ) : (
        sample
      )}
    </Flex>
  );
};
