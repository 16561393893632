import { action, observable, makeObservable, computed } from 'mobx';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { ResourceCategory, ResourceType } from '@stores/alertDefinitionsList/types';
import {
  constructActiveAlerts,
  constructAlertsOverviewData,
  filterAndUpdateRetlAlertsByConnections,
} from './utils';
import { ActiveAlert, RawActiveAlertsData, Category, ActiveAlertsOverview } from './types';

const ALERTS_API = '/alertNotifications/alerts/active';

export interface IActiveAlertsStore {
  activeAlertsByCategory: Record<Category, ActiveAlert[]>;
  activeAlertsOverview: Record<Category, ActiveAlertsOverview | null>;
  alertsConfigured: Record<Category, boolean>;
  activeAlertsLoading: boolean;
  showActiveAlerts: boolean;
  getActiveAlertsByResource: (
    category: Category,
    resourceDetails: {
      sourceId?: string;
      destinationId?: string;
      trackingPlanId?: string;
      connectionId?: string;
    },
  ) => ActiveAlert[];
  load(): Promise<void>;
}

export class ActiveAlertsStore implements IActiveAlertsStore {
  private rootStore: IRootStore;

  @observable private activeAlerts: ActiveAlert[] = [];

  @observable public alertsConfigured = {
    [Category.EVENT_STREAM]: false,
    [Category.RETL]: false,
    [Category.TRACKING_PLAN]: false,
  };

  @observable public activeAlertsLoading = false;

  @observable private alertsFeatureAvailable = false;

  @observable private errorInLoadingActiveAlerts = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @computed
  public get showActiveAlerts() {
    return this.alertsFeatureAvailable && !this.errorInLoadingActiveAlerts;
  }

  @computed
  public get activeAlertsByCategory() {
    const {
      healthDashboardStore: {
        retlHealthStore: { syncsData },
      },
    } = this.rootStore;

    return {
      [Category.EVENT_STREAM]: this.activeAlerts.filter(
        (alert) =>
          alert.resourceCategory === ResourceCategory.EVENT_STREAM &&
          (alert.resourceType === ResourceType.CLOUD_DESTINATION ||
            alert.resourceType === ResourceType.WAREHOUSE_DESTINATION),
      ),
      [Category.RETL]: filterAndUpdateRetlAlertsByConnections(
        this.activeAlerts.filter((alert) => alert.resourceCategory === ResourceCategory.RETL),
        syncsData,
      ),
      [Category.TRACKING_PLAN]: this.activeAlerts.filter((alert) => !!alert.trackingPlanId),
    };
  }

  @computed
  public get activeAlertsOverview() {
    if (!this.showActiveAlerts) {
      return {
        [Category.EVENT_STREAM]: null,
        [Category.RETL]: null,
        [Category.TRACKING_PLAN]: null,
      };
    }

    return constructAlertsOverviewData(
      this.alertsConfigured,
      this.activeAlertsByCategory,
      this.activeAlertsLoading,
    );
  }

  public getActiveAlertsByResource = (
    category: Category,
    resourceDetails: {
      sourceId?: string;
      destinationId?: string;
      trackingPlanId?: string;
      connectionId?: string;
    },
  ) => {
    const { sourceId, destinationId, trackingPlanId, connectionId } = resourceDetails;
    const alerts = this.activeAlertsByCategory[category];
    return alerts.filter(
      (alert) =>
        (!alert.sourceId || !sourceId || alert.sourceId === sourceId) &&
        (!alert.destinationId || !destinationId || alert.destinationId === destinationId) &&
        (!alert.trackingPlanId || !trackingPlanId || alert.trackingPlanId === trackingPlanId) &&
        (!alert.jobId || !connectionId || alert.jobId === connectionId),
    );
  };

  @action.bound
  public async load() {
    this.errorInLoadingActiveAlerts = false;
    this.alertsFeatureAvailable = this.rootStore.featuresStore.has('NOTIFICATION_SUBSCRIPTIONS');

    if (!this.alertsFeatureAvailable) {
      return;
    }
    const { messagesStore, alertDefinitionsListStore } = this.rootStore;

    this.activeAlertsLoading = true;
    await alertDefinitionsListStore.load();

    try {
      const { data } = await apiAuthCaller().get<RawActiveAlertsData>(ALERTS_API);
      this.activeAlerts = constructActiveAlerts(
        data.activeAlerts,
        alertDefinitionsListStore.alertDefinitions,
      );
      this.alertsConfigured = {
        [Category.EVENT_STREAM]: data.alertConfigurationStatus.hasEventStreamDestinationAlerts,
        [Category.RETL]: data.alertConfigurationStatus.hasRETLAlerts,
        [Category.TRACKING_PLAN]: data.alertConfigurationStatus.hasTrackingPlanAlerts,
      };
    } catch (e) {
      messagesStore.showErrorMessage('Failed to load active alerts');
      this.errorInLoadingActiveAlerts = true;
    } finally {
      this.activeAlertsLoading = false;
    }
  }
}
