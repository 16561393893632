import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import { TimeFilterValue } from '@components/common/types';
import { readableThreshold } from '@components/alertNotifications/utils';
import { IRootStore } from '@stores/index';
import { AlertDefinition } from '@stores/alertDefinitionsList/types';
import { SyncData } from './rEtlHealth';
import {
  ActiveAlert,
  Category,
  RawActiveAlert,
  TrackingPlanData,
  TrackingPlanPeriodData,
  TrackingPlanResponseData,
} from './types';
import { timeFilters } from './constants';

export const getTimeParams = (
  timeFilter: TimeFilterValue,
  type: 'current' | 'previous' = 'current',
) => {
  const { dayCount } = timeFilters[timeFilter];
  const offset = type === 'previous' ? 1 : 0;
  const startOfUnit = timeFilter === TimeFilterValue.MONTH ? 'day' : 'hour';
  const currentTime = dayjs.utc();

  return {
    start: currentTime
      .subtract((offset + 1) * dayCount, 'days')
      .startOf(startOfUnit)
      .toISOString(),
    end: currentTime.subtract(offset * dayCount, 'days').toISOString(),
  };
};

export const convertToPercentage = (successSum: number, abortSum: number): number => {
  if (abortSum === 0 && successSum === 0) {
    return 0;
  }
  const percentage = (abortSum * 100) / (successSum + abortSum);
  const precision = 100; // 2 decimal places
  const roundedNumber = Math.round((percentage + Number.EPSILON) * precision) / precision;
  return roundedNumber;
};

export const getPercentChange = (
  currentValue: number,
  pastValue: number,
  isPercentage: boolean,
) => {
  if (isPercentage) {
    return currentValue - pastValue;
  }
  if (pastValue === 0) {
    if (currentValue === 0) {
      return 0;
    }
    return 100;
  }
  return ((currentValue - pastValue) / pastValue) * 100;
};

export const getRoundedValue = (value: number, decimalPlaces: number): string => {
  const precision = 10 ** decimalPlaces;
  const roundedNumber = Math.abs(Math.round((value + Number.EPSILON) * precision) / precision);
  return `${roundedNumber === value ? '' : '~'}${roundedNumber}`;
};

export const transformTrackingPlansData = (
  connections: TrackingPlanResponseData,
  { sourcesListStore, trackingPlanListStore }: IRootStore,
) => {
  const sources = connections
    .map((connection) => {
      const source = sourcesListStore.sourceById(connection.sourceId);
      const trackingPlan = trackingPlanListStore.getById(connection.trackingPlanId);
      if (!source || !trackingPlan) {
        return null;
      }
      return {
        id: `${source.id}-${trackingPlan.id}`,
        source,
        trackingPlanId: connection.trackingPlanId,
        trackingPlanName: trackingPlan.name,
        trackingPlanVersion: trackingPlan.version,
        eventsValidated: connection.eventsValidated,
        violationCount: connection.violationCount,
        eventsWithViolations: connection.eventsWithViolations,
        isActive: source.trackingPlanConfig?.trackingPlanId === connection.trackingPlanId,
      };
    })
    .filter((source) => source !== null) as TrackingPlanData[];
  const sourcesWithViolation = sources.filter((source) => source.violationCount > 0);

  const sourcesCount = Object.keys(groupBy(sourcesWithViolation, 'id')).length;
  return {
    overview: {
      total: sources.reduce((acc, source) => acc + source.eventsWithViolations, 0),
      sourcesCount,
      violationCount: sources.reduce((acc, source) => acc + source.violationCount, 0),
    },
    sources,
  };
};

export const constructTrackingPlanPeriodData = (
  current: TrackingPlanData[] = [],
  prev: TrackingPlanData[] = [],
): TrackingPlanPeriodData[] =>
  current.map((currentTrackingPlan) => {
    const prevTrackingPlan = prev?.find(
      (prevTrackingPlan) => prevTrackingPlan.id === currentTrackingPlan.id,
    );
    return {
      ...currentTrackingPlan,
      prevEventsValidated: prevTrackingPlan?.eventsValidated || 0,
      prevViolationCount: prevTrackingPlan?.violationCount || 0,
    };
  });

export const constructActiveAlerts = (
  rawActiveAlerts: RawActiveAlert[],
  alertDefinitions: AlertDefinition[],
) => {
  const alerts = rawActiveAlerts.map((a) => {
    const alertDef = alertDefinitions.find((alertDef) => alertDef.name === a.alertDefinitionName);

    if (!alertDef) {
      return null;
    }

    return {
      ...a,
      activeAt: dayjs(a.activeAt),
      alertMessage: alertDef?.messageTemplate || '', // Alert message will be available from notification service v3.6.2
      alertDisplayName: alertDef.displayName,
      resourceCategory: alertDef.resourceCategory,
      resourceType: alertDef.resourceType,
      threshold: readableThreshold(a.threshold, alertDef?.thresholdMetadata?.unit),
    };
  });

  return alerts.filter((alert) => alert !== null) as ActiveAlert[];
};

export const constructAlertsOverviewData = (
  alertsConfigured: Record<Category, boolean>,
  activeAlertsByCategory: Record<Category, ActiveAlert[]>,
  loading: boolean,
) => {
  const eventStreamAlertsConfigured = alertsConfigured[Category.EVENT_STREAM];
  const eventStreamAlerts = activeAlertsByCategory[Category.EVENT_STREAM];
  const eventStreamAlertCount = eventStreamAlerts.length;
  const eventStreamDestinationsWithAlerts = uniq(
    eventStreamAlerts.map((a) => a.destinationId),
  ).length;

  const retlAlertsConfigured = alertsConfigured[Category.RETL];
  const retlAlerts = activeAlertsByCategory[Category.RETL];
  const retlAlertCount = retlAlerts.length;
  const retlConnectionsWithAlerts = uniq(
    retlAlerts.map((a) => `${a.sourceId}-${a.destinationId}-${a.jobId}`),
  ).length;

  const trackingPlanAlertsConfigured = alertsConfigured[Category.TRACKING_PLAN];
  const trackingPlanAlerts = activeAlertsByCategory[Category.TRACKING_PLAN];
  const trackingPlanAlertCount = trackingPlanAlerts.length;
  const trackingPlanSourcesWithAlerts = uniq(trackingPlanAlerts.map((a) => a.sourceId)).length;

  return {
    [Category.EVENT_STREAM]: {
      loading,
      enabled: eventStreamAlertsConfigured,
      alertCount: eventStreamAlertCount,
      resourceCount: eventStreamDestinationsWithAlerts,
      title: 'Event Stream alerts',
      resourceType: 'destination',
    },
    [Category.RETL]: {
      loading,
      enabled: retlAlertsConfigured,
      alertCount: retlAlertCount,
      resourceCount: retlConnectionsWithAlerts,
      title: 'Reverse ETL alerts',
      resourceType: 'connection',
    },
    [Category.TRACKING_PLAN]: {
      loading,
      enabled: trackingPlanAlertsConfigured,
      alertCount: trackingPlanAlertCount,
      resourceCount: trackingPlanSourcesWithAlerts,
      title: 'Tracking Plan alerts',
      resourceType: 'source',
    },
  };
};

export const filterAndUpdateRetlAlertsByConnections = (
  alerts: ActiveAlert[],
  syncsData: SyncData[],
) =>
  alerts.slice().filter((alert) => {
    const sync = syncsData.find(
      (sync) =>
        (!alert.sourceId || alert.sourceId === sync.source.id) &&
        (!alert.destinationId || alert.destinationId === sync.destination.id) &&
        (!alert.jobId || alert.jobId === sync.connectionId),
    );
    if (sync) {
      alert.destinationId = sync.destination.id;
      alert.jobId = sync.connectionId;
    }
    return !!sync;
  });
