import * as React from 'react';
import { TimePicker as UITimePicker } from '@ui-library/datepicker';
// eslint-disable-next-line custom/no-antd-imports
import { TimePickerProps } from 'antd';
import { FixMe } from '@utils/types';
import dayjs, { Dayjs } from 'dayjs';
import { RedAsterisk } from '@components/common/redAsterisk';
import { LargeText } from '@ui-library/typographyV2';

export interface ITimePickerOptions {
  omitLabel?: boolean;
  omitSeconds?: boolean;
  minuteStep?: TimePickerProps['minuteStep'];
}

interface ITimePickerProps {
  field: {
    time?: dayjs.Dayjs;
    value?: FixMe;
    default: FixMe;
    label: string;
    required?: boolean;
  };
  options?: ITimePickerOptions;
  onChange: (label: string, value: string) => void;
  disabled?: boolean;
}

interface ITimePickerStates {
  time: dayjs.Dayjs | null;
}

/* eslint-disable import/no-default-export */
export default class TimePicker extends React.Component<ITimePickerProps, ITimePickerStates> {
  constructor(props: ITimePickerProps) {
    super(props);
    this.state = { time: props.field.time || null };
  }

  componentDidMount() {
    const { field, onChange } = this.props;
    this.setState({ time: field.default });
    onChange(field.value, field.default);
  }

  static getDerivedStateFromProps(
    nextProps: Partial<ITimePickerProps>,
    currState: ITimePickerStates,
  ) {
    // In below changing the time if there is a change in the default value.
    if (nextProps.field!.default !== currState.time) {
      return { time: nextProps.field!.default };
    }
    return null;
  }

  change = (time: Dayjs | null, timeString: string) => {
    const { field, onChange } = this.props;
    this.setState({ time });
    onChange(field.value, timeString);
  };

  public render() {
    const { field, options, disabled } = this.props;
    return (
      <div className="p-t-sm" data-testid="timePicker">
        <LargeText className="m-b-xs">
          {field.label}
          {field.required && <RedAsterisk />}
        </LargeText>
        <UITimePicker
          data-testid={field.label}
          className="full-width"
          disabled={disabled}
          onChange={this.change}
          value={this.state.time ? dayjs(this.state.time, 'HH:mm') : undefined}
          minuteStep={options?.minuteStep || 1}
          format="HH:mm"
          size="large"
          inputReadOnly
        />
      </div>
    );
  }
}
