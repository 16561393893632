import { action, observable, makeObservable } from 'mobx';
import { apiCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import orderBy from 'lodash/orderBy';
import { Group, Schema } from '@components/common/formSchema';
import { DestinationCategory, RudderScope } from './types';
import { DestUIConfigV2 } from '@components/destinations/destination-view/configurationV2/formComponents/types';

export interface IDestinationDefsListStore {
  destinationDefs: IDestDefinition[];
  rootStore: IRootStore;
  getDestinationDefs(): void;
  getDestinationDef(id: string): IDestDefinition | undefined;
}

interface IAuthConfig {
  provider?: 'Google';
  type: string;
  secretFields: unknown[];
  nameField: string;
  config: Schema;
  role: string;
  rudderScopes?: RudderScope[];
}

export type ConnectionMode = 'cloud' | 'device' | 'hybrid';

export interface Config {
  features?: ['vdm-next'];
  supportedSourceTypes: string[];
  supportedConnectionModes: {
    [key: string]: ConnectionMode[];
  };
  syncBehaviours: string[];
  destConfig: {
    defaultConfig: string[];
    [key: string]: string[];
  };
  preview: boolean;
  auth: IAuthConfig;
  deleteSupport: boolean;
  supportsVisualMapper: boolean;
  isAudienceSupported: boolean;
  disableJsonMapper?: boolean;
  supportsBlankAudienceCreation?: boolean;
  immutableKeys?: string[];
}

interface DestinationFeatures {
  vdmLabels: {
    newAudienceAlternateLabel: string;
    existingAudienceAlternateLabel: string;
    audienceAlternateLabel: string;
  };
}
export interface IDestDefinition {
  id: string;
  name: string;
  displayName: string;
  category: DestinationCategory;
  config: Config;
  options: {
    isBeta?: boolean;
    hidden?: boolean | { featureFlagName: string; featureFlagValue: boolean };
    deprecated?: boolean;
    deprecationLabel?: string;
    destinationFeatures?: DestinationFeatures;
    supportsCustomMappings?: boolean;
  } | null;
  isPopular: boolean;
  uiConfig: Group[] | DestUIConfigV2;
}

const popularDestinations = [
  'WEBHOOK',
  'S3',
  'AM',
  'BQ',
  'POSTGRES',
  'SNOWFLAKE',
  'MP',
  'RS',
  'HS',
];

export class DestinationDefsListStore implements IDestinationDefsListStore {
  @observable public destinationDefs: IDestDefinition[] = [];

  @observable public rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  public async getDestinationDefs() {
    const res = await apiCaller().get<Omit<IDestDefinition, 'isPopular'>[]>(
      '/web/destination-definitions',
    );
    this.destinationDefs = orderBy(
      res.data.map((x) => ({
        id: x.id,
        name: x.name,
        displayName: x.displayName,
        category: x.category,
        config: x.config,
        uiConfig: x.uiConfig,
        options: x.options,
      })),
      [(dest) => dest.displayName?.toString().toLowerCase()],
      ['asc'],
    ).map((a) => ({ ...a, isPopular: popularDestinations.includes(a.name) }));
  }

  public getDestinationDef(id: string) {
    return this.destinationDefs.find((def: IDestDefinition) => def.id === id);
  }
}
