import { Exposure } from '@amplitude/experiment-js-client';
import { stores } from '@stores/.';
import { LanguageType } from '@stores/transformationsList';
import { Traits } from '@stores/user';
import { Category, SubCategories } from '@stores/healthDashboard/types';
import { ResourceCategory, ResourceType } from '@stores/alertDefinitionsList/types';
import { ChannelType } from '@components/alertNotifications/stores/types';

type Track<X, Y> = {
  event: X;
  properties: Y;
};

type TrackPayload =
  | Track<'New Integration requested', { integration: string }>
  | Track<
      'destinationCreated',
      {
        destinationId: string;
        destinationType: string;
        workspaceId?: string;
        organizationId?: string;
      }
    >
  | Track<'destinationSetupStarted', { destinationType: string }>
  | Track<'destinationSetupStepCompleted', { step: number; destinationType: string }>
  | Track<'viewedLiveEvents', { resourceType: 'source' | 'destination' | 'transformation' }>
  | Track<'inviteAccepted', { inviteEmail: string }>
  | Track<'gettingStartedChecklistCompleted', Record<string, never>>
  | Track<'userCompletedOnboarding', Traits>
  | Track<'sourceSetupStarted', { sourceType: string }>
  | Track<'sourceSetupStepCompleted', { step: number; sourceType: string }>
  | Track<'sourceCreated', { sourceId: string; sourceType: string }>
  | Track<'ads_cnv_app_signup', Record<string, never>>
  | Track<'clickWebApp', Record<string, string>>
  | Track<'mutinySignup', { spz1: string }>
  | Track<'sourceDisabled', { sourceId: string; sourceType: string }>
  | Track<'sourceDeleted', { sourceId: string; sourceType: string }>
  | Track<'destinationDeleted', { destinationId: string; destinationType: string }>
  | Track<'destinationDisabled', { destinationId: string; destinationType: string }>
  | Track<'$exposure', Exposure>
  | Track<'clickNewTransformationButton', { usersId: string }>
  | Track<
      'newTransformationViewed',
      {
        usersId: string;
        transformationTemplateType: 'custom' | 'template';
      }
    >
  | Track<
      'newTransformationCreated',
      {
        transformationId: string;
        transformationTemplateType: 'custom' | 'template';
        transformationLanguageType: LanguageType;
      }
    >
  | Track<
      'transformationConnectedToDestination',
      {
        destinationId: string;
        transformationId: string;
        destinationMode: 'Cloud Mode' | 'Device Mode';
      }
    >
  | Track<
      'transformationDisconnectedFromDestination',
      { destinationId: string; transformationId: string }
    >
  | Track<'transformationLibraryCreated', { libraryId: string }>
  | Track<
      'transformationTested',
      { transformationId?: string; transformationLanguageType: LanguageType }
    >
  | Track<
      'ImportEventTypeTesting',
      {
        transformationId?: string;
        importEventType: string;
      }
    >
  | Track<
      'transformationLogs',
      { transformationId?: string; transformationLanguageType: LanguageType }
    >
  | Track<
      'transformationSplitScreen',
      {
        transformationId?: string;
        transformationLanguageType: string;
        splitDirection: 'vertical' | 'horizontal';
      }
    >
  | Track<
      'transformationDeleted',
      {
        transformationId?: string;
        transformationLanguageType: LanguageType;
        destinationMode: 'Cloud Mode' | 'Device Mode';
      }
    >
  | Track<'transformationNotifications', { transformationId?: string }>
  | Track<'transformationEventsPageViewed', { transformationId?: string }>
  | Track<
      'transformationDiffViewed',
      { transformationId?: string; transformationLanguageType: LanguageType }
    >
  | Track<'login', { authMethod?: string }>
  | Track<'healthTabViewed', { resourceCategory: Category }>
  | Track<
      'resourceSelected',
      {
        resourceCategory: Category;
        sourceId?: string;
        destinationId?: string;
        trackingPlanId?: string;
      }
    >
  | Track<
      'destinationTypeFilterClicked',
      {
        filter: SubCategories[Category.EVENT_STREAM];
      }
    >
  | Track<
      'statusFilterClicked',
      {
        resourceCategory: Category.EVENT_STREAM | Category.TRACKING_PLAN;
        filter: 'failures' | 'violations' | 'all';
      }
    >
  | Track<
      'retlFilterClicked',
      {
        type: 'source' | 'destination' | 'status';
        sourceId?: string;
        destinationId?: string;
        status?: string;
      }
    >
  | Track<
      'visitResourceClicked',
      {
        resourceCategory: Category;
        sourceId?: string;
        destinationId?: string;
      }
    >
  | Track<'retryInitiated', { destinationId: string; errorCategory?: string; sourceId?: string }>
  | Track<
      'workspaceAlertsViewed',
      {
        resourceCategory: ResourceCategory;
      }
    >
  | Track<
      'resourceAlertsViewed',
      {
        resourceCategory: ResourceCategory;
        resourceType: ResourceType;
        sourceId?: string;
        destinationId?: string;
        profileId?: string;
      }
    >
  | Track<
      'channelToggled',
      {
        channelType: ChannelType;
        resourceCategory: ResourceCategory;
        toggleValue: boolean;
      }
    >
  | Track<
      'alertToggled',
      {
        resourceCategory: ResourceCategory;
        alertName: string;
        toggleValue: boolean;
        resourceType?: ResourceType;
        sourceId?: string;
        destinationId?: string;
        profileId?: string;
      }
    >
  | Track<
      'resetCustomAlertsClicked',
      {
        resourceCategory: ResourceCategory;
        resourceType: ResourceType;
        sourceId?: string;
        destinationId?: string;
        profileId?: string;
      }
    >;

const enhanceEvent = (
  props: TrackPayload['properties'] & {
    userId?: never;
  },
) => {
  const { userStore, organizationStore, workspaceStore } = stores;
  const { loggedIn } = userStore;
  if (loggedIn) {
    return {
      workspaceId: workspaceStore.id,
      organizationId: organizationStore.id || undefined,
      ...props,
    };
  }
  return props;
};

export const Analytics = {
  identify: (userId: string, traits: Record<string, unknown>) => {
    window.rudderanalytics.identify(userId, traits);
  },

  track: (payload: TrackPayload) => {
    const { event, properties } = payload;
    window?.rudderanalytics?.track(event, enhanceEvent(properties));
  },

  group: (groupId: string, traits: Record<string, unknown>) => {
    window?.rudderanalytics?.group(groupId, traits);
  },
};
