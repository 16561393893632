import React from 'react';
import Editor, { DiffEditor, EditorProps } from '@monaco-editor/react';
import { EditorContainer } from './styles';
import { CopyToClipboard } from '../icons/copyToClipboard';

type MonacoLanguageType = 'javascript' | 'python' | 'json' | 'text' | 'yaml';

interface IMonacoEditorProps extends Omit<EditorProps, 'onChange'> {
  code: string;
  height?: string;
  onChange?: (code: string) => void;
  readOnly?: boolean;
  enableCopy?: boolean;
  hideLineNumber?: boolean;
}

interface IMonacoDiffEditorProps extends EditorProps {
  height?: string;
  original: string;
  modified: string;
  language: MonacoLanguageType;
}

export const MonacoEditor = ({
  code,
  language,
  onChange,
  readOnly,
  enableCopy,
  hideLineNumber,
  className,
  options = {},
  ...restProps
}: IMonacoEditorProps) => {
  const onCodeChange = (code: string | undefined) => {
    if (onChange) {
      onChange(code || '');
    }
  };
  const height = Object.prototype.hasOwnProperty.call(restProps, 'height')
    ? restProps.height
    : '500px';

  return (
    <EditorContainer className={className}>
      {enableCopy && (
        <CopyToClipboard className="copy_button" content={code} showIcon={false}>
          Copy
        </CopyToClipboard>
      )}
      <Editor
        width="100%"
        theme="light"
        language={language}
        value={code}
        onChange={onCodeChange}
        options={{
          lineNumbers: hideLineNumber ? 'off' : undefined,
          minimap: {
            enabled: false,
          },
          lightbulb: {
            enabled: false,
          },
          readOnly,
          fontSize: 13,
          scrollBeyondLastLine: false,
          overviewRulerLanes: 0,
          scrollbar: { alwaysConsumeMouseWheel: false },
          lineHeight: 1.4,
          ...options,
        }}
        {...restProps}
        height={height}
      />
    </EditorContainer>
  );
};

export const MonacoDiffEditor = ({
  height = '500px',
  className,
  original,
  modified,
  language,
}: IMonacoDiffEditorProps) => (
  <EditorContainer className={className}>
    <DiffEditor
      width="100%"
      height={height}
      theme="light"
      language={language}
      original={original}
      modified={modified}
      options={{
        minimap: {
          enabled: false,
        },
        readOnly: true,
        fontSize: 13,
        wordWrap: 'on',
        scrollBeyondLastLine: false,
        overviewRulerLanes: 0,
        scrollbar: { alwaysConsumeMouseWheel: false },
        lineHeight: 1.4,
      }}
    />
  </EditorContainer>
);
