import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TypeOption } from './types';
import theme from '@css/theme';

// @todo use ditto
export enum DATA_CATALOG_TABS {
  EVENTS = 'Events',
  PROPERTIES = 'Properties',
}

export const DATA_CATALOG_API_PREFIX = '/catalog';

export const API_URLS = {
  events: `${DATA_CATALOG_API_PREFIX}/events`,
  allEvents: `${DATA_CATALOG_API_PREFIX}/events/all`,
  eventMetrics: (event: { name: string; eventType: string }) =>
    `/events?eventName=${event.name}&eventType=${event.eventType}`,
  singleEvent: (id: string) => `${DATA_CATALOG_API_PREFIX}/events/${id}`,
  properties: `${DATA_CATALOG_API_PREFIX}/properties`,
  allProperties: `${DATA_CATALOG_API_PREFIX}/properties/all`,
  singleProperty: (id: string) => `${DATA_CATALOG_API_PREFIX}/properties/${id}`,
  categories: `${DATA_CATALOG_API_PREFIX}/categories`,
  singleCategory: (id: string) => `${DATA_CATALOG_API_PREFIX}/categories/${id}`,
};

export const EVENT_TYPE_OPTIONS = [
  { label: 'Track', value: 'track' },
  { label: 'Identify', value: 'identify' },
  { label: 'Page', value: 'page' },
  { label: 'Screen', value: 'screen' },
  { label: 'Group', value: 'group' },
];

export const categoriesToIcons = {
  conversion: solid('money-bill-transfer'),
  marketing: solid('megaphone'),
  onboarding: solid('handshake'),
  general: solid('tag'),
};

export const EVENT_TYPE_TAG_COLORS = {
  track: {
    color: theme.token.colorPrimaryText,
    backgroundColor: theme.token['blue.2'],
  },
  identify: {
    color: theme.token['magenta.8'],
    backgroundColor: theme.token['magenta.3'],
  },
  page: {
    color: theme.token['teal.8'],
    backgroundColor: theme.token['teal.2'],
  },
  screen: {
    color: theme.token['gray.8'],
    backgroundColor: theme.token['gray.4'],
  },
  group: {
    color: theme.token['gold.8'],
    backgroundColor: theme.token['gold.4'],
  },
};

export const EDITOR_ERROR_MESSAGES = {
  requiredField: 'This field is required.',
  invalidString: (entity: string) =>
    `${entity} must be between 3 and 65 characters long, start with a letter and contain only letters, numbers, underscores, commas, spaces, dashes and dots.`,
  invalidPropertyName: 'This field must be at least 1 characters long and start with a letter.',
  nameExists: 'Name must be unique.',
  invalidDescription:
    'Description must be between 3 and 255 characters long and start with a letter.',
  typeExists: (type: string) => `There is already a property with this name and ${type} type.`,
  trackEventCannotBeChanged: 'Track event type cannot be changed.',
};

export const PROPERTY_NAME_REGEX = /^[A-Z_a-z].{0,65}$/;

export const STRING_REGEX = /^[A-Z_a-z][\s\w,.-]{2,65}$/;

export const DESCRIPTION_REGEX = /^[A-Z_a-z].{2,254}$/;

export const PROPERTY_TYPES: TypeOption[] = [
  { label: 'Object', value: 'object', icon: solid('brackets-curly') },
  { label: 'String', value: 'string', icon: solid('worm') },
  { label: 'Number', value: 'number', icon: solid('hashtag') },
  { label: 'Integer', value: 'integer', icon: solid('square-5') },
  { label: 'Array', value: 'array', icon: solid('brackets-square') },
  { label: 'Boolean', value: 'boolean', icon: solid('object-subtract') },
  { label: 'Null', value: 'null', icon: solid('battery-empty') },
];

export enum DATA_CATALOG_QUERY_PARAMS {
  ID = 'id',
}
