import React, { SyntheticEvent, ReactNode } from 'react';
import { Button, ButtonProps } from '@ui-library/button';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMessage } from '@hooks/useMessage';

type CopyToClipboardProps =
  | {
      content: string;
      message?: string;
      className?: string;
      id?: string;
      children?: ReactNode;
      showIcon?: boolean;
      size?: ButtonProps['size'];
    }
  | {
      content: string;
      message?: string;
      type: 'icon';
      className?: string;
    };

export const CopyToClipboard = (props: CopyToClipboardProps) => {
  const { message, context } = useMessage();

  const onClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    navigator.clipboard.writeText(props.content);
    message('info', props.message ?? 'Copied', 1);
  };

  if ('type' in props) {
    return (
      <>
        {context}
        <Button
          shape="circle"
          type="text"
          icon={<FontAwesomeIcon icon={regular('copy')} />}
          onClick={onClick}
          className={props.className}
        />
      </>
    );
  }

  const { id, className, children, showIcon = true, size } = props;
  return (
    <>
      {context}
      <Button
        type="default"
        id={id}
        icon={showIcon && <FontAwesomeIcon icon={regular('copy')} />}
        onClick={onClick}
        className={className}
        size={size}
      >
        {children}
      </Button>
    </>
  );
};
