import {
  DESTINATION_CATEGORIES,
  SOURCE_CATEGORIES,
  RUDDER_SCOPES,
} from '@components/common/constants';
import { FilterSpecProps } from './audiences/types';

export enum WarehouseSourceOrigin {
  TABLE = 'table',
  MODEL = 'model',
  AUDIENCE = 'audience',
  PROFILES_TABLE = 'profiles-table',
  PROFILES_AUDIENCE = 'profiles-audience',
}

export type SourceCategory =
  | (typeof SOURCE_CATEGORIES)[keyof typeof SOURCE_CATEGORIES]
  | 'webhook'
  | null;

export type DestinationCategory =
  | (typeof DESTINATION_CATEGORIES)[keyof typeof DESTINATION_CATEGORIES]
  | null;

export type RudderScope = keyof typeof RUDDER_SCOPES;

export interface WarehouseConfigConstant {
  key: string;
  value: string;
}

export interface ISourceInfo {
  accountId?: string;
  table?: string;
  schema?: string;
  bucketName?: string;
  objectPrefix?: string;
  sqlModelId?: string;
  sql?: string;
  filterSpec?: FilterSpecProps;
  audienceId?: string;
}
