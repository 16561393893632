import React from 'react';
import { DatePicker as AntDatePicker, TimePicker as AntTimePicker, TimePickerProps } from 'antd';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';

export const TimePicker: React.FC<TimePickerProps> = ({ size = 'large', ...rest }) => (
  <AntTimePicker size={size} {...rest} />
);

export const DatePicker: React.FC<DatePickerProps> = ({ size = 'large', ...rest }) => (
  <AntDatePicker size={size} {...rest} />
);

export const RangePicker: React.FC<RangePickerProps> = ({ size = 'large', ...rest }) => (
  <AntDatePicker.RangePicker size={size} {...rest} />
);
