import React from 'react';
import { DateFormat } from '@components/common/pickers/dateFormat';
import { H3 } from '@ui-library/typography';
import { Flex } from '@ui-library/flex';
import { Button } from '@ui-library/button';
import useModalView from '@hooks/useModalView';
import Modal from '@ui-library/modal/custom';
import classes from './accountCredentials.module.scss';
import { Container } from './styles';
import useStores from '@stores/useStores';
import { OptionCard } from '@ui-library/radio';
import { BaseText } from '@ui-library/typographyV2';

interface Props {
  accounts: { id: string; name: string; metadata?: { email: string }; createdAt: string }[];
  onSelect: (id: string) => void;
  selectedAccount: string;
  onDelete: (id: string) => void;
  isDeleteInProgress: boolean;
  disabled?: boolean;
}

/* eslint-disable import/no-default-export */
export default ({
  accounts,
  disabled,
  onSelect,
  selectedAccount,
  onDelete,
  isDeleteInProgress,
}: Props) => {
  const handleDelete = async () => {
    await onDelete(selectedAccount);
    showDeleteModal(false);
  };

  const [deleteModal, showDeleteModal] = useModalView(
    <Modal
      title="Delete Account"
      onSave={handleDelete}
      onClose={() => showDeleteModal(false)}
      size="sm"
      saveLabel="Confirm"
      content="Are you sure you want to remove this account?"
      loading={isDeleteInProgress}
    />,
  );

  const { permissionsStore } = useStores();

  const onChange = (id: string) => {
    onSelect(id);
  };

  return (
    <>
      {deleteModal}
      <Container justifyContent="space-between">
        <H3>Choose an account</H3>
        {selectedAccount && accounts.length > 1 && !disabled && (
          <Button
            type="primary"
            variant="danger"
            name="Delete"
            disabled={
              !permissionsStore.hasPermission({ resourceType: 'workspace', action: 'edit' })
            }
            onClick={() => showDeleteModal(true)}
          />
        )}
      </Container>
      <div className={`${classes.list} m-b-md`}>
        <Flex flexGrow gap="0.5rem" flexDirection="column">
          {accounts.map((account) => (
            <OptionCard
              flexGrow
              disabled={disabled}
              isSelected={selectedAccount === account.id}
              onClick={disabled ? () => null : () => onChange(account.id)}
              flexDirection="column"
            >
              <BaseText>{account.name}</BaseText>
              <BaseText>{account.metadata?.email}</BaseText>
              <BaseText>
                <>
                  <span>Created at </span>
                  <DateFormat date={account.createdAt} format="DD MMM yyyy" />
                </>
              </BaseText>
            </OptionCard>
          ))}
        </Flex>
      </div>
    </>
  );
};
