import React from 'react';
// eslint-disable-next-line custom/no-antd-imports
import { message as messageUI } from 'antd';
import { BaseText } from '@ui-library/typographyV2';
// eslint-disable-next-line custom/no-antd-imports
import { NoticeType } from 'antd/es/message/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const typeToClass = {
  info: 'info-message',
  success: 'success-message',
  error: 'error-message',
  warning: 'warning-message',
  loading: 'loading-message',
};

const iconMap = {
  info: <FontAwesomeIcon icon={solid('info-circle')} />,
  success: <FontAwesomeIcon icon={solid('circle-check')} />,
  error: <FontAwesomeIcon icon={solid('circle-x')} />,
  warning: <FontAwesomeIcon icon={solid('circle-exclamation')} />,
  loading: <FontAwesomeIcon spin icon={solid('spinner-third')} />,
};

export const useMessage = () => {
  const [messageApi, context] = messageUI.useMessage();

  const message = (type: NoticeType, message: string, duration?: number) =>
    messageApi.open({
      type,
      content: <BaseText fontWeight={500}>{message}</BaseText>,
      className: typeToClass[type],
      duration,
      icon: iconMap[type],
    });

  return { message, context };
};
