export enum ResourceType {
  CLOUD_DESTINATION = 'cloud_destination',
  WAREHOUSE_DESTINATION = 'warehouse_destination',
  SOURCE = 'source',
  PROFILE = 'profile',
}

export enum ResourceCategory {
  EVENT_STREAM = 'event_stream',
  RETL = 'retl',
  PROFILES = 'profiles',
}

export type ThresholdSliderUnit = 'percentage' | 'time_seconds';

export interface AlertDefinition {
  id: string;
  name: string;
  displayName: string;
  description: string;
  messageTemplate?: string; // Alert message will be available from notification service v3.6.2
  resourceType: ResourceType;
  resourceCategory: ResourceCategory;
  defaultThreshold?: number;
  thresholdMetadata?: {
    min: number;
    max: number;
    step: number;
    tooltip: string;
    unit: ThresholdSliderUnit;
  };
}
