import React from 'react';
import { DatePicker as UIDatePicker } from '@ui-library/datepicker';
import dayjs from 'dayjs';
import { LargeText } from '@ui-library/typographyV2';

interface IField {
  default?: string;
  format?: string; // default iso
  displayFormat?: string; // default MMM DD, YYYY
  value: string;
  label: string;
  required?: boolean;
  defaultOpen?: boolean;
}

interface DatePickerProps {
  field: IField;
  onChange: (label: string, value?: string) => void;
  disabled?: boolean;
}

const formatDateResult = (date: dayjs.Dayjs | null, format = 'iso') => {
  if (!date) {
    return undefined;
  }
  if (format === 'iso') {
    return date.toISOString();
  }
  return date.format(format);
};

const Picker = (
  field: IField,
  setDate: (date: string | undefined) => void,
  disabled?: boolean,
  date?: string,
  format = 'MMM DD, YYYY',
) => (
  <UIDatePicker
    size="large"
    allowClear={false}
    onChange={(date) => {
      setDate(formatDateResult(date, field.format));
    }}
    disabledDate={(currentDate) => currentDate.isAfter(dayjs())}
    defaultValue={field.default ? dayjs(field.default) : undefined}
    format={format}
    disabled={disabled}
    defaultOpen={field.defaultOpen}
    value={date ? dayjs(date) : undefined}
    data-testid={field.label}
  />
);

const DatePicker = ({ field, onChange, disabled }: DatePickerProps) => {
  const [date, setDate] = React.useState(formatDateResult(dayjs(field.default), field.format));

  React.useEffect(() => {
    onChange(field.value, date);
  }, [date]);
  field.default = field.default || new Date().toString();
  return (
    <div className="p-t-sm">
      <LargeText className="m-b-xs">
        {field.label}
        {field.required && (
          <span style={{ color: 'red' }} data-testid="required">
            {' '}
            *
          </span>
        )}
      </LargeText>
      {Picker(field, setDate, disabled, date)}
    </div>
  );
};

export { Picker };

/* eslint-disable import/no-default-export */
export default DatePicker;
