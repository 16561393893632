import { DefaultTheme } from 'styled-components';

export type PaddingVariants =
  | 'paddingXXS'
  | 'paddingXS'
  | 'paddingSM'
  | 'padding'
  | 'paddingMD'
  | 'paddingLG'
  | 'paddingXL'
  | 'paddingXXL'
  | 'padding3XL'
  | 'padding4XL'
  | 'padding5XL';

export type BorderRadiusVariants =
  | 'borderRadiusXS'
  | 'borderRadiusSM'
  | 'borderRadius'
  | 'borderRadiusLG'
  | 'borderRadiusXL'
  | 'borderRadiusXXL'
  | 'borderRadius3XL'
  | 'borderRadius4XL'
  | 'borderRadius5XL';

export const createBorderRadiusVariant =
  (defaultValue?: BorderRadiusVariants) =>
  ({
    theme,
    borderRadius = defaultValue,
  }: {
    theme: DefaultTheme;
    borderRadius?: BorderRadiusVariants;
  }) =>
    !borderRadius ? '' : `border-radius: ${theme.token[borderRadius]}px;`;

export const createPaddingVariant =
  (defaultValue?: PaddingVariants) =>
  ({ theme, padding = defaultValue }: { theme: DefaultTheme; padding?: PaddingVariants }) =>
    !padding ? '' : `padding: ${theme.token[padding]}px;`;
