import { SOURCE_CATEGORIES } from '@components/common/constants';
import CloudSource from './cloudSource';
import WarehouseSource from './warehouseSource';
import EventStreamSource from './eventStreamSource';
import SingerSource from './singerSource';
import { SourceCategory } from '@stores/types';

/* eslint-disable import/no-default-export */
export default (type: SourceCategory | undefined) => {
  switch (type) {
    case SOURCE_CATEGORIES.CLOUD:
      return new CloudSource();
    case SOURCE_CATEGORIES.SINGER:
      return new SingerSource();
    case SOURCE_CATEGORIES.WAREHOUSE:
      return new WarehouseSource();
    default:
      return new EventStreamSource();
  }
};
