import styled from 'styled-components';
import theme from '@css/theme';

const colorStyle = ({ color }: { color?: string }) => `
  color: ${color || theme.token.colorText};
`;

export const H1 = styled.h1<{ color?: string }>`
  font-family: ${theme.text.fontFamily.secondary};
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.token.fontSizeHeading1}px;
  line-height: 2.875rem;
  ${colorStyle};
`;

export const H2 = styled.h2<{ color?: string }>`
  font-family: ${theme.text.fontFamily.secondary};
  font-weight: 400;
  font-size: ${theme.token.fontSizeHeading2}px;
  line-height: 2.5rem;
  ${colorStyle};
`;

export const H3 = styled.h3<{ color?: string }>`
  font-family: ${theme.text.fontFamily.secondary};
  font-weight: 500;
  font-size: ${theme.token.fontSizeHeading3}px;
  line-height: 2rem;
  ${colorStyle};
`;

export const H4 = styled.h4<{ color?: string }>`
  font-family: ${theme.text.fontFamily.secondary};
  font-weight: 400;
  font-size: ${theme.token.fontSizeHeading4}px;
  line-height: 1.875rem;
  ${colorStyle};
`;

export const H5 = styled.h5<{ color?: string }>`
  font-family: ${theme.text.fontFamily.secondary};
  font-weight: 500;
  font-size: ${theme.token.fontSizeHeading5}px;
  line-height: 1.75rem;
  ${colorStyle};
`;

export const BaseText = styled.span<{ fontWeight?: 600 | 500; color?: string }>`
  font-family: ${theme.text.fontFamily.primary};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  font-size: ${theme.token.fontSize}px;
  color: ${({ color }) => color || theme.token.colorText};
  line-height: 1.375rem;
`;

export const SmallText = styled.span<{ fontWeight?: 600 | 500; color?: string }>`
  font-family: ${theme.text.fontFamily.primary};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  font-size: ${theme.token.fontSizeSM}px;
  color: ${({ color }) => color || theme.token.colorText};
  line-height: 1.25rem;
`;

export const LargeText = styled.span<{ strong?: boolean; color?: string }>`
  display: inline-block;
  font-family: ${theme.text.fontFamily.primary};
  font-weight: ${({ strong }) => (strong ? 600 : 400)};
  font-size: ${theme.token.fontSizeLG}px;
  color: ${({ color }) => color || theme.token.colorText};
  line-height: 1.5rem;
`;

export const XLargeText = styled.span<{ strong?: boolean; color?: string }>`
  font-family: ${theme.text.fontFamily.primary};
  font-weight: ${({ strong }) => (strong ? 600 : 400)};
  font-size: ${theme.token.fontSizeXL}px;
  color: ${({ color }) => color || theme.token.colorText};
  line-height: 1.625rem;
`;

export const XXLargeText = styled(XLargeText)<{ strong?: boolean; color?: string }>`
  font-size: ${theme.token.fontSizeHeading4}px;
`;
