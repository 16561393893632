import { action, observable, makeObservable } from 'mobx';
import { apiCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { SourceCategory } from './types';
import { Group } from '@components/common/formSchema';

type SourceType =
  | 'cloud'
  | 'cloudSource'
  | 'warehouse'
  | 'web'
  | 'android'
  | 'ios'
  | 'unity'
  | 'reactnative'
  | 'amp'
  | 'flutter'
  | 'cordova';
export interface ISourceDefinitionsListStore {
  sourceDefinitions: ISourceDefinition[];
  rootStore: IRootStore;
  getAll(): void;
  getById(id: string): ISourceDefinition | undefined;
  getByCategory(category: SourceCategory): ISourceDefinition[];
  getByName(name: string): ISourceDefinition | undefined;
}

type WarehouseSourceOptions = {
  isSqlModelSupported?: boolean;
  syncBehaviours: string[];
  isCredentialsValidationSupported?: boolean;
  isAudienceSupported?: boolean;
  syncReportType?: string;
  supportsSyncSettings?: boolean;
};

export type AuthOptions = {
  provider?: 'Google' | 'Xero';
  oauthRole?: string;
};

const popularSources = [
  'Javascript',
  'HTTP',
  'Android',
  'Node',
  'iOS',
  'Python',
  'AMP',
  'snowflake',
  'google_sheets',
  'webhook',
];

interface Field {
  trim?: boolean;
  type?: string;
  label?: string;
  regex?: string;
  value?: string;
  required?: boolean;
  regexErrorMessage?: string;
  addInAccountSummary?: boolean;
  placeholder?: string;
}

export interface UiConfig {
  auth?: {
    type: string;
    secretFields?: string[];
    nameField: string;
    config?: Group[];
    role?: string;
  };
  title?: string;
  sectionNote?: string;
  fields?: Array<Field>;
  docLinks?: {
    jsonMapperUseInstructions?: string;
    setupInstructions?: string;
    grantPermissions?: string;
    verifyingCredentials?: string;
  };
  config?: Group[];
  schemaAlias?: string;
  secretFields?: string[];
  nameField?: string;
}

export interface ISourceDefinition {
  id: string;
  name: string;
  category: SourceCategory;
  displayName: string;
  options:
    | ({
        isBeta: boolean;
        image: string;
        hidden?: boolean;
        deprecated?: boolean;
        deprecationLabel?: string;
        supportsDestinationSyncMode?: boolean;
        auth?: AuthOptions;
        gaMetricIds?: string[];
        gaDimensionIds?: string[];
      } & WarehouseSourceOptions)
    | null;
  isPopular: boolean;
  uiConfig: UiConfig[] | UiConfig | null;
  type: SourceType;
}

export class SourceDefinitionsListStore implements ISourceDefinitionsListStore {
  @observable public sourceDefinitions: ISourceDefinition[] = [];

  @observable public rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  public async getAll() {
    const res =
      await apiCaller().get<Omit<ISourceDefinition, 'isPopular'>[]>('/web/source-definitions');
    const modifiedSourceDefs = res.data.map((x) => ({
      id: x.id,
      name: x.name,
      displayName: x.displayName,
      category: x.category,
      uiConfig: x.uiConfig,
      options: x.options,
      type: x.type,
    }));

    this.sourceDefinitions = modifiedSourceDefs
      .filter((x) => !!x.displayName)
      .sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1))
      .map((a) => ({ ...a, isPopular: popularSources.includes(a.name) }));
  }

  @action.bound
  public getById(id: string) {
    return this.sourceDefinitions.find((def: ISourceDefinition) => def.id === id);
  }

  public getByCategory(category: SourceCategory) {
    return this.sourceDefinitions.filter((def) => def.category === category);
  }

  public getByName(name: string) {
    return this.sourceDefinitions.find((def) => def.name === name);
  }
}
