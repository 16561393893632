import React from 'react';
import { FixMe } from '@utils/types';
// eslint-disable-next-line custom/no-antd-imports
import AntSelect, { SelectProps, SelectValue } from 'antd/lib/select';
import { Select, SelectFieldVariant } from '@ui-library/select';

import { Container } from './styles';
import { RedAsterisk } from '@components/common/redAsterisk';
import { LargeText } from '@ui-library/typographyV2';

const { Option } = AntSelect;

interface SelectFieldV1Props extends Omit<SelectProps<FixMe>, 'options'> {
  defaultValue?: FixMe;
  required?: boolean;
  label?: string;
  hasError?: boolean;
  options?: { value: FixMe; label: string; disabled?: boolean }[] | string[];
  variant?: SelectFieldVariant;
}

/** @deprecated - Use SelectFieldV2 */
export const SelectFieldV1 = (props: SelectFieldV1Props) => {
  const {
    defaultValue,
    options,
    disabled,
    value,
    onChange,
    label,
    required,
    loading,
    hasError,
    ...restProps
  } = props;
  const optionsData: FixMe[] =
    (options && typeof options[0] === 'string'
      ? (options as FixMe[]).map((o: string) => ({ label: o, value: o }))
      : options) || [];
  return (
    <Container className="single-select_container" data-testid="selectCont">
      <LargeText>
        {label}
        {required && <RedAsterisk data-testid="required" />}
      </LargeText>
      <Select
        hasError={hasError}
        data-testid={label}
        disabled={disabled || loading}
        loading={loading}
        value={value}
        {...restProps}
        onChange={onChange}
      >
        {optionsData.map((opt: FixMe) => (
          <Option disabled={opt.disabled} value={opt.value} key={opt.value} label={opt.label}>
            {opt.label}
          </Option>
        ))}
      </Select>
    </Container>
  );
};

interface SelectFieldV2Props<T extends SelectValue = SelectValue> extends SelectProps<T> {
  defaultValue?: T;
  required?: boolean;
  label?: string;
  hasError?: boolean;
  variant?: SelectFieldVariant;
}

export const SelectFieldV2 = <T extends SelectValue = SelectValue>(
  props: SelectFieldV2Props<T>,
) => {
  const {
    options = [],
    disabled,
    value,
    mode,
    onChange,
    label,
    required,
    loading,
    hasError,
    className,
    children,
    variant,
    ...restProps
  } = props;
  const containerClassName = `single-select_container ${className}${
    variant ? ` single-select_container-variant-${variant}` : ''
  }`;

  return (
    <Container className={containerClassName} data-testid="selectCont">
      <LargeText className="label">
        {label}
        {required && <RedAsterisk data-testid="required" />}
      </LargeText>
      <Select
        data-testid={label}
        hasError={hasError}
        disabled={disabled || loading}
        mode={mode}
        loading={loading}
        value={value}
        onChange={onChange}
        variant={variant}
        {...restProps}
      >
        {options.map((option) => (
          <Option
            disabled={option.disabled}
            value={option.value}
            key={option.value}
            label={option.label}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </Container>
  );
};
