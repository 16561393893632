import get from 'lodash/get';
import {
  Field,
  FormConfig,
  FormData,
  LegacyPreRequisiteField,
  PreRequisiteFeatureFlag,
  PreRequisiteField,
  PreRequisites,
  RowFields,
} from '@components/common/formSchema/types';

// Transform legacy property preRequisiteField values to the v2 prerequisite.field ones
const legacyPreRequisiteFieldToV2 = (
  legacyPreRequisite: LegacyPreRequisiteField,
): PreRequisiteField => ({
  configKey: legacyPreRequisite.name,
  value: legacyPreRequisite.selectedValue,
  ...legacyPreRequisite,
});

// Normalise the value of preRequisiteField as array
const getLegacyPreRequisiteFields = (
  preRequisiteField?: LegacyPreRequisiteField | LegacyPreRequisiteField[],
): LegacyPreRequisiteField[] => {
  let legacyPreRequisiteFields: LegacyPreRequisiteField[] = [];

  if (Array.isArray(preRequisiteField)) {
    legacyPreRequisiteFields = preRequisiteField;
  } else if (preRequisiteField) {
    legacyPreRequisiteFields = [preRequisiteField];
  }

  return legacyPreRequisiteFields;
};

const getFieldPreRequisites = (field: Field | RowFields): PreRequisiteField[] => {
  const legacyPreRequisiteFields: LegacyPreRequisiteField[] = getLegacyPreRequisiteFields(
    (field as Field).preRequisiteField,
  );
  let aggregatedPreRequisiteFields: PreRequisiteField[] = [];

  if (field.preRequisites && Array.isArray(field.preRequisites.fields)) {
    aggregatedPreRequisiteFields = field.preRequisites.fields;
  }

  // Transform any legacy prerequisite configuration to v2 one
  if (legacyPreRequisiteFields.length > 0) {
    legacyPreRequisiteFields.forEach((legacyPreRequisite) => {
      aggregatedPreRequisiteFields.push(legacyPreRequisiteFieldToV2(legacyPreRequisite));
    });
  }

  // Merge both legacy and v2 configurations prerequisite fields
  // for legacy form builder the condition property is always implied as AND (the default)
  return aggregatedPreRequisiteFields;
};

const checkPreRequisiteField = (preReqField: PreRequisiteField, formData: FormData): boolean => {
  const formValue = get(formData, preReqField.configKey);
  const hasMatchOnExactValue =
    Object.prototype.hasOwnProperty.call(preReqField, 'value') && preReqField.value === formValue;
  const hasMatchOnEmptyValue =
    !Object.prototype.hasOwnProperty.call(preReqField, 'value') && !formValue;

  // hard check for OAuth dependency
  if (Object.prototype.hasOwnProperty.call(preReqField, 'exists')) {
    const valueExistOnForm = !!formValue;
    return valueExistOnForm;
  }

  return hasMatchOnExactValue || hasMatchOnEmptyValue;
};

const checkPreRequisiteFlag = (
  preReqFlag: PreRequisiteFeatureFlag,
  availableFeatureFlags: Record<string, boolean>,
): boolean => {
  const hasMatchOnExactValue =
    Object.prototype.hasOwnProperty.call(preReqFlag, 'value') &&
    preReqFlag.value === get(availableFeatureFlags, preReqFlag.configKey);
  const hasMatchOnEmptyValue =
    !Object.prototype.hasOwnProperty.call(preReqFlag, 'value') &&
    !get(availableFeatureFlags, preReqFlag.configKey);

  return hasMatchOnExactValue || hasMatchOnEmptyValue;
};

// Check if all preRequisite checks pass
const preRequisitesCheck = (
  preRequisites: PreRequisites | undefined,
  config: FormConfig | FormData,
  availableFeatureFlags: Record<string, boolean> = {},
): boolean => {
  const preReqFields = preRequisites?.fields;
  const preReqFeatureFlags = preRequisites?.featureFlags;
  const preReqCondition = preRequisites?.condition || 'and';
  const preReqFeatureFlagsCondition = preRequisites?.featureFlagsCondition || 'and';
  let isCheckPassed = true;
  let preReqFieldCheckResult = true;
  let preReqFlagCheckResult = true;
  const hasPreReqFields = preReqFields && Array.isArray(preReqFields) && preReqFields.length > 0;
  const hasPreReqFeatureFlags =
    preReqFeatureFlags && Array.isArray(preReqFeatureFlags) && preReqFeatureFlags.length > 0;

  if (!hasPreReqFields && !hasPreReqFeatureFlags) {
    return isCheckPassed;
  }

  if (hasPreReqFields) {
    preReqFieldCheckResult =
      preReqCondition === 'or'
        ? preReqFields.some((preReqField) => checkPreRequisiteField(preReqField, config))
        : preReqFields.every((preReqField) => checkPreRequisiteField(preReqField, config));
  }

  if (hasPreReqFeatureFlags) {
    preReqFlagCheckResult =
      preReqFeatureFlagsCondition === 'or'
        ? preReqFeatureFlags.some((preReqField) =>
            checkPreRequisiteFlag(preReqField, availableFeatureFlags),
          )
        : preReqFeatureFlags.every((preReqField) =>
            checkPreRequisiteFlag(preReqField, availableFeatureFlags),
          );
  }

  isCheckPassed = preReqFieldCheckResult && preReqFlagCheckResult;

  return isCheckPassed;
};

export { getLegacyPreRequisiteFields, getFieldPreRequisites, preRequisitesCheck };
