import React, { ReactNode } from 'react';
import { ErrorLabel } from '@components/common/errorLabel';
import { isValidJson } from '@components/common/util/util';
import styled from 'styled-components';
import { InputField, PasswordInput, TextArea } from '@ui-library/input';
import { Tooltip } from '@ui-library/tooltip';
import { FixMe } from '@utils/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TooltipContainer } from './style';
import { RedAsterisk } from '@components/common/redAsterisk';
import { LargeText } from '@ui-library/typographyV2';

const Container = styled.div`
  input {
    width: 100%;
  }
`;

interface ITextInputFieldProps {
  field: {
    value?: FixMe;
    default: FixMe;
    readOnly?: unknown;
    regex?: FixMe;
    inputFieldType?: 'number';
    required?: boolean;
    regexErrorMessage?: string;
    trim?: unknown;
    subType?: 'JSON';
    dependsOn?: string;
    placeholder?: string;
    min?: string | number;
    label: unknown;
    labelNote?: ReactNode;
    infoTooltip?: string;
    secret?: unknown;
  };
  type: 'input' | 'textarea';
  suffix?: ReactNode;
  disabled?: boolean;
  secret?: string;
  formData?: Record<string, unknown>;
  onChange: (label: string, value: string, error?: boolean) => void;
  width?: number;
  testId?: string;
}

interface ITextInputFieldState {
  value: string;
  error: boolean;
  errorMessage: string;
}

/* eslint-disable import/no-default-export */
export default class TextInputField extends React.Component<
  ITextInputFieldProps,
  ITextInputFieldState
> {
  constructor(props: ITextInputFieldProps) {
    super(props);
    this.state = {
      value: this.props.field.default,
      error: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    const { field, onChange, secret } = this.props;
    if (!secret) {
      onChange(field.value, field.default || '');
    }
  }

  static getDerivedStateFromProps(props: ITextInputFieldProps, state: ITextInputFieldState) {
    const { field, onChange } = props;
    /* The below condition is to update the value of the input component if it is a
    'readOnly' field and extracts value from some other field using 'obtainValueFromField'
    condition. Also, setting an error if the value does not satisfy the regular exp. */
    if (field.readOnly && field.default !== undefined && field.default !== state.value) {
      const isError = TextInputField.handleValidation(field.regex, field.default);
      const errorMessage = isError ? field.regexErrorMessage || 'Wrong Format' : '';
      onChange(field.value, field.default, isError);
      return {
        ...state,
        value: field.default,
        error: isError,
        errorMessage,
      };
    }
    return null;
  }

  static handleValidation = (regexString: string, value: string, required?: boolean) => {
    const regex = RegExp(regexString);
    const isValidInput =
      (!required && value.length === 0) || regex.test(value) || (value && value.startsWith('env.'));
    return !isValidInput;
  };

  onTextAreaInputChange = (newValue: string) => {
    const { field, onChange } = this.props;
    const { subType, trim, value } = field;
    if (subType === 'JSON' && !isValidJson(newValue)) {
      this.setState({ error: true, errorMessage: 'Wrong JSON Format' });
    } else {
      this.setState({ error: false, errorMessage: '' });
    }
    const finalValue = trim ? newValue.trim() : newValue;

    onChange(value, finalValue);
  };

  onInputChange = (newValue: FixMe) => {
    const { field, onChange } = this.props;
    const { inputFieldType, regex, required, regexErrorMessage, value, trim } = field;
    let updatedValue = newValue;
    let finalValue = newValue;
    if (inputFieldType === 'number') {
      updatedValue = Number(updatedValue);
      finalValue = updatedValue;
    } else if (trim) {
      finalValue = finalValue.trim();
    }
    let isError = false;
    if (regex) {
      isError = TextInputField.handleValidation(regex, updatedValue, required);
    }
    const errorMsg = isError ? regexErrorMessage || 'Wrong Format' : '';
    this.setState({ value: updatedValue, error: isError, errorMessage: errorMsg });
    onChange(value, finalValue, isError);
  };

  getInputElement = (placeholder: string | undefined) => {
    const { field, type, secret, suffix, width, disabled, testId } = this.props;
    const { value } = this.state;
    const { label, inputFieldType, min, trim, secret: secretField } = field;
    const useSecretField = !!secret || secretField;

    if (type === 'input') {
      if (useSecretField) {
        return (
          <PasswordInput
            testId={label as string}
            defaultValue={field.default}
            width={width || 500}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={(e) => this.onInputChange(e.target.value)}
            onBlur={(e) => {
              const val = e.target.value;
              this.onInputChange(trim ? val.trim() : val);
            }}
          />
        );
      }
      return (
        <InputField
          suffix={suffix}
          min={min}
          defaultValue={field.default}
          width={width || 500}
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            this.onInputChange(e.target.value);
          }}
          onBlur={(e) => {
            const val = e.target.value;
            this.onInputChange(trim ? val.trim() : val);
          }}
          type={inputFieldType}
          disabled={disabled}
          data-testid={testId || label}
        />
      );
    }

    return (
      <TextArea
        defaultValue={field.default}
        placeholder={placeholder}
        onChange={(e) => {
          this.onTextAreaInputChange(e.currentTarget.value);
        }}
        onBlur={(e) => {
          const val = e.currentTarget.value;
          this.onTextAreaInputChange(trim ? val.trim() : val);
        }}
        disabled={disabled}
        data-testid={testId || label}
      />
    );
  };

  public render() {
    const { field, type, secret, formData, disabled } = this.props;
    const { value, error, errorMessage } = this.state;

    if (field.dependsOn && formData && !formData[field.dependsOn]) {
      return null;
    }

    const { readOnly, label, labelNote, infoTooltip, required } = field;
    let { placeholder } = field;
    if (secret !== null && secret !== undefined) {
      placeholder = secret;
    } else if (disabled && !readOnly && field.secret) {
      // Show 8-stars string for secrets for which don't fetch value from the config-backend.
      placeholder = '********';
    } else if (disabled) {
      placeholder = '';
    }

    const inputElement = this.getInputElement(placeholder);

    const textInput =
      disabled && !field.secret ? (
        <Tooltip title={value}>
          <span data-testid="toolTip">{inputElement}</span>
        </Tooltip>
      ) : (
        inputElement
      );

    const errorView = error && <ErrorLabel error={error} errorMessage={errorMessage} />;
    let fieldRequirement;
    /* infoTooltip is a note added to show the condition
    that needs to be fulfilled for a required field. */
    if (infoTooltip) {
      fieldRequirement = (
        <TooltipContainer>
          <Tooltip title={field.infoTooltip}>
            <FontAwesomeIcon icon={regular('circle-info')} />
          </Tooltip>
        </TooltipContainer>
      );
    } else if (required) {
      fieldRequirement = <RedAsterisk />;
    }

    return (
      <Container className="text-input__container">
        <form autoComplete="new-password">
          {(label || fieldRequirement) && (
            <LargeText className="m-b-xs">
              {label}
              {fieldRequirement}
            </LargeText>
          )}
          {type === 'input' ? (
            <span>
              {textInput}
              {errorView}
            </span>
          ) : (
            <>
              {textInput}
              {errorView}
            </>
          )}
          {labelNote && <div className="p-b-sm p-t-xs">{labelNote}</div>}
        </form>
      </Container>
    );
  }
}
