import { experiment } from '@lib/experiment';
import { Variant } from '@amplitude/experiment-js-client';

/**
 * Example destination experiment payload
 *
 * ```json
 *
 * { "FB_CUSTOM_AUDIENCE": true, "GOOGLE_ADS": true}
 *
 * ```
 */

export enum DestinationExperimentKeys {
  'enableManyToOneByDestinationType' = 'enable-many-to-one-by-destination-type',
}

// ts-prune-ignore-next
export const destinationExperimentFallbackValue = {
  [DestinationExperimentKeys.enableManyToOneByDestinationType]: {
    fallback: {},
  },
};

type DestinationExperimentVariant = Variant & {
  payload: { [key: string]: boolean };
};

export const isFeatureEnabledForDestination = (
  destinationType: string,
  flag: DestinationExperimentKeys,
) => {
  const { fallback } = destinationExperimentFallbackValue[flag];
  const variant = (experiment.getVariant(flag, fallback) as DestinationExperimentVariant) ?? {
    payload: fallback,
  };

  return (variant?.payload ?? fallback)[destinationType] === true;
};
