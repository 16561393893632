import styled from 'styled-components';
import { Flex } from '@ui-library/flex';
import theme from '@css/theme';
import { BaseText } from '@ui-library/typographyV2';

export const AccountsContainer = styled(Flex)`
  max-height: 360px;
  overflow: auto;
`;
export const CredentialsRow = styled(BaseText)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CredentialsDrawerContainer = styled(Flex)`
  padding: ${theme.token.paddingLG}px;
`;
