import dayjs from 'dayjs';
import { ISourceStore } from '@stores/source';
import { ResourceCategory, ResourceType } from '@stores/alertDefinitionsList/types';

export interface TrackingPlanData {
  id: string;
  source: ISourceStore;
  trackingPlanName: string;
  trackingPlanId: string;
  trackingPlanVersion: string;
  eventsValidated: number;
  violationCount: number;
  eventsWithViolations: number;
  isActive: boolean;
}

export interface TrackingPlanPeriodData extends TrackingPlanData {
  prevEventsValidated: number;
  prevViolationCount: number;
}

export type TrackingPlanResponseData = {
  sourceId: string;
  trackingPlanId: string;
  eventsValidated: number;
  violationCount: number;
  eventsWithViolations: number;
}[];

export enum Category {
  EVENT_STREAM = 'eventStream',
  RETL = 'retl',
  TRACKING_PLAN = 'trackingPlan',
}

export type SubCategories = {
  eventStream: 'all' | 'cloud' | 'warehouse';
  retl: '';
  trackingPlan: '';
};

type StatusFilterValue = {
  eventStream: 'all' | 'failures';
  retl: 'all';
  trackingPlan: 'all' | 'violations';
};

export interface TableFilter {
  category: Category;
  subCategory: SubCategories[Category];
  status: StatusFilterValue[Category];
}

export interface OverviewData {
  key: Category;
  title: string;
  description: string;
  total: number;
  loading: boolean;
  categories: {
    label: string;
    value: number;
  }[];
  alerts: ActiveAlertsOverview | null;
}

export interface ActiveAlertsOverview {
  enabled: boolean;
  alertCount: number;
  resourceCount: number;
  loading: boolean;
  title: string;
  resourceType: string;
}

export interface RawActiveAlert {
  sourceId?: string;
  destinationId?: string;
  trackingPlanId?: string;
  jobId?: string;
  alertDefinitionName: string;
  activeAt: string;
  threshold?: number;
}

export interface ActiveAlert extends Omit<RawActiveAlert, 'activeAt' | 'threshold'> {
  resourceCategory: ResourceCategory;
  resourceType: ResourceType;
  alertDisplayName: string;
  alertMessage: string;
  activeAt: dayjs.Dayjs;
  threshold: string;
}

export interface RawActiveAlertsData {
  activeAlerts: RawActiveAlert[];
  alertConfigurationStatus: {
    hasEventStreamDestinationAlerts: boolean;
    hasRETLAlerts: boolean;
    hasTrackingPlanAlerts: boolean;
  };
}
