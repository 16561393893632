import { ISourceDataStore } from '@stores/sourceDataStore';
import { IMessageStore } from '@stores/messages';
import React, { FunctionComponent, useEffect, useState } from 'react';
import findIndex from 'lodash/findIndex';
import { inject, observer } from 'mobx-react';
import { ValidationState } from '@components/common/types';
import SourceFactory from '@components/sources/sourceFactory';
import { FixMe } from '@utils/types';
import classes from './credential-popup.module.scss';
import { ISourceDefinition, UiConfig } from '@stores/sourceDefinitionsList';
import { isOauthEnabledSource } from '@components/common/util/authUtil';
import useStores from '@stores/useStores';
import { Drawer } from '@ui-library/drawer';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LargeText } from '@ui-library/typographyV2';
import { Button } from '@ui-library/button';
import { Flex } from '@ui-library/flex';
import { useScrollToRef } from '@hooks/useScrollToRef';
import { FeatureFlagKeys, experiment } from '@lib/experiment';

interface ICredentialsState {
  accountId?: string;
  accountInfo?: FixMe;
  updated?: boolean;
  submit?: boolean;
}

interface OwnProps {
  onCancel: () => void;
  sourceDataStore?: ISourceDataStore;
  messagesStore?: IMessageStore;
}

type Props = OwnProps;

const CredentialPopup: FunctionComponent<Props> = (props: Props) => {
  const { sourceDataStore: source, messagesStore, onCancel } = props;
  const [credentials, setCredentials] = useState<ICredentialsState>({});
  const [showLoader, setShowLoader] = useState(false);
  const [validationState, setValidationState] = useState<ValidationState>(ValidationState.hide);
  const { ref } = useScrollToRef({ predicate: validationState === ValidationState.loading });
  const { retlSourceListStore } = useStores();
  const sourceInstance = SourceFactory(source?.sourceDef.category);
  const isValidationDisabled =
    !sourceInstance.enableCredentialValidations() ||
    (credentials?.accountInfo?.role === 's3' &&
      credentials?.accountInfo?.options?.connectionMode !== 'crossAccountRole');

  const [isNextEnabled, setNextEnabled] = useState(false);

  const isNewAccountName = async (account_name: string) => {
    const { accounts } = await source!.getCredentialInfo();
    const accountIndex = findIndex(
      accounts,
      (account: FixMe) =>
        account.options?.rudder_account_name &&
        account.options?.rudder_account_name === account_name,
    );
    return accountIndex === -1;
  };

  const isOauthSource = (sourceDef: ISourceDefinition | undefined) => {
    const authType = (sourceDef?.uiConfig as UiConfig)?.auth?.type;
    return !!authType && isOauthEnabledSource(authType);
  };

  const handleChange = async (
    accountId: string,
    accountInfo: FixMe,
    isValid?: boolean,
    submit?: boolean,
  ) => {
    if (
      (validationState === ValidationState.passed ||
        validationState === ValidationState.failed ||
        validationState === ValidationState.notStarted) &&
      !isValidationDisabled
    ) {
      checkValid(ValidationState.notStarted, Boolean(credentials.accountId && !accountId));
      /* when the selected accountId changes, make the validation
    run again when pressed on NEXT. */
    } else {
      setNextEnabled(!!isValid); // setting the save button based on isvalid.
    }
    setCredentials({ accountId, accountInfo, updated: true, submit });
  };

  const checkAccountId = async () => {
    const { accountId, accountInfo } = credentials;
    if (!accountId && accountInfo) {
      return sourceInstance.validateAccount({
        source,
        isNewAccountName,
        isOauthSource,
        accountInfo,
        setCredentials,
        showErrorMessage: messagesStore?.showErrorMessage,
        accountId,
        valid: isNextEnabled,
      });
    }
    return accountId;
  };

  const skipVerify =
    experiment.isFeatureEnabled(FeatureFlagKeys.skipRetlSourcesValidation) &&
    validationState === ValidationState.failed;

  const handleSave = async () => {
    if (
      validationState !== ValidationState.passed &&
      validationState !== ValidationState.disable &&
      !isValidationDisabled &&
      !skipVerify
    ) {
      checkValid(ValidationState.loading);
      return;
    }
    if (!isNextEnabled || !credentials?.updated) {
      return;
    }
    setShowLoader(true);
    const accountId = await checkAccountId();
    setShowLoader(false);
    if (!accountId) {
      return;
    }

    setShowLoader(true);
    const retlSource = retlSourceListStore.getRetlSourceById(source?.id || '');
    if (retlSource) {
      await retlSource.update({ accountId });
      source?.setAccount(accountId);
    } else {
      await source!.updateConfig({ accountId });
    }
    setShowLoader(false);
    props.onCancel();
  };

  useEffect(() => {
    if (credentials?.submit) {
      handleSave();
    }
  }, [credentials?.submit, handleSave]);

  const checkValid = (result?: ValidationState, isAccountDeleted?: boolean) => {
    if (isValidationDisabled) {
      setValidationState(ValidationState.hide);
      return;
    }
    setValidationState(result || ValidationState.hide);
    setNextEnabled(
      (result === ValidationState.passed ||
        result === ValidationState.notStarted ||
        result === ValidationState.failed) &&
        !isAccountDeleted,
    );
  };

  const authComp = (
    <div className={classes.auth_container}>
      <div className={classes.auth_container__wrapper}>
        {sourceInstance.getAuthComponent({
          checkValid,
          source,
          credentials,
          isValidationLoading: validationState,
          isValidationDisabled,
          handleChange,
          ref,
        })}
      </div>
    </div>
  );

  return (
    <Drawer
      open
      onClose={onCancel}
      placement="right"
      title={
        <Flex alignItemsCenter spaceBetween>
          <Flex gap="0.5rem" alignItemsCenter>
            <FontAwesomeIcon fontSize={16} icon={regular('database')} />
            <LargeText strong>Edit credentials</LargeText>
          </Flex>

          <Flex gap="16px">
            <Button size="middle" data-testid="cancel-button" type="default" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              disabled={!isNextEnabled}
              loading={validationState === ValidationState.loading || showLoader}
              onClick={handleSave}
              size="middle"
              data-testid="btnSave"
            >
              {validationState === ValidationState.passed || isValidationDisabled || skipVerify
                ? 'Save'
                : 'Verify'}
            </Button>
          </Flex>
        </Flex>
      }
      width="736px"
    >
      {authComp}
    </Drawer>
  );
};

/* eslint-disable import/no-default-export */
export default inject(
  'sourceDataStore',
  'messagesStore',
  'workspaceStore',
)(observer(CredentialPopup));
